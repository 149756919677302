// libs
import styled from "styled-components";
// theme
import { colors } from "src/theme/colors";

export const InputWrapper = styled.div`
  position: relative;
`;

export const SuggestionWrapper = styled.div`
  position: absolute;
  background-color: ${colors.white};
  border: 1px solid ${colors.greyLight};
  border-radius: 8px;

  padding-block: 8px;
  top: 64px;
  left: -12px;
  right: -12px;
  z-index: 1;
`;

export const SuggestionItem = styled.div`
  cursor: pointer;
  padding: 12px 16px;
  color: ${colors.greyDark};

  :hover,
  :focus,
  :active {
    background-color: ${colors.mainLight};
  }
`;

export const SearchIconWrapper = styled.div`
  width: 18px;
  height: 18px;
  margin-right: 16px;
`;
