import React, { memo, forwardRef, ForwardedRef } from "react";
// theme
import { InputWrapper, Input, Container } from "./input.styles";
// types
import { IInputProps } from "./input.types";
import { UseFormRegisterReturn } from "react-hook-form";
import { Caption } from "src/theme/typography";
import { colors } from "src/theme";

const InputComponent = (
  { leftIcon, rightIcon, label, register, errors, ...rest }: IInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const REGISTER: UseFormRegisterReturn = register || ({} as UseFormRegisterReturn);
  const { name: REGISTER_NAME = "", ...registerSpread } = REGISTER;

  const name = REGISTER_NAME || label;

  return (
    <Container gap="8px">
      <InputWrapper
        error={!!errors}
        // do not change id
        id="InputComponent_Wrapper"
      >
        {leftIcon}
        <Input {...rest} name={name} {...registerSpread} ref={ref} />
        {rightIcon}
      </InputWrapper>
      {errors && <Caption textColor={colors.redDark}>{errors.message}</Caption>}
    </Container>
  );
};

export default memo(forwardRef(InputComponent));
