import React, { FC } from "react";
// libs
import NextImage from "next/image";
import GooglePlacesAutocomplete, { PropTypes } from "react-places-autocomplete";
// components
import Input from "src/components/ui/input";
// hooks
import { useRouter } from "next/router";
import { useAppSelector } from "src/hooks";
// redux
import { authUserGeometrySelector } from "src/redux/slices";
// theme
import { InputWrapper, SuggestionItem, SuggestionWrapper, SearchIconWrapper } from "./styles";
// utils
import { getCountry } from "src/utils/countries";
// types
import { IGeometry } from "src/constants/coordinates";

function makeOptions(
  userGeometry: IGeometry,
  userCountry: string | undefined,
): {
  bounds?: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral | undefined;
  componentRestrictions?: google.maps.GeocoderComponentRestrictions | undefined;
  location?: google.maps.LatLng | google.maps.LatLngLiteral | undefined;
  offset?: string | number | undefined;
  radius?: string | number | undefined;
  sessionToken?: any;
  types?: string[] | undefined | undefined;
} {
  if (typeof window === "undefined") return {};

  return {
    types: ["(regions)"],
    location: new google.maps.LatLng({
      lng: userGeometry.center.lng,
      lat: userGeometry.center.lat,
    }),
    radius: 2000,
    componentRestrictions: {
      country: userCountry,
    },
  };
}

const searchIcon = (
  <SearchIconWrapper>
    <NextImage priority src="/icons/search.svg" width={17.5} height={17.5} layout="fixed" />
  </SearchIconWrapper>
);

interface IPlacesAutocomplete extends Omit<PropTypes, "children"> {
  onEnterCallback?: (address: string, placeID: string, obj?: any) => Promise<void>;
  onBlurCallback?: (address: string) => Promise<void>;
  onFocusCallback?: () => Promise<void>;
}

const PlacesAutocomplete: FC<IPlacesAutocomplete> = ({
  onEnterCallback,
  onBlurCallback,
  onFocusCallback,
  ...props
}) => {
  const router = useRouter();

  const userCountry = getCountry(router.locale, "locale")?.short_name;
  const userGeometry: IGeometry = useAppSelector(authUserGeometrySelector);

  return (
    <GooglePlacesAutocomplete
      debounce={300}
      searchOptions={makeOptions(userGeometry, userCountry)}
      {...props}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        let placeholder = "Your area or postcode";
        if (router.locale?.toLowerCase() === "en-us") {
          placeholder = "Your area or ZIP code";
        }
        if (router.locale?.toLowerCase() === "en-ca") {
          placeholder = "Your area or postal code";
        }
        if (router.locale?.toLowerCase() === "en-ie") {
          placeholder = "Your area or Eircode";
        }

        const inputProps = getInputProps({ placeholder });

        const handleKeyDown = (event: any) => {
          inputProps.onKeyDown(event);

          if (event.keyCode === 13 && !!suggestions.length) {
            const sug = suggestions[0];
            onEnterCallback?.(sug.description, sug.placeId);
          }
        };

        const handleBlur = (event: any) => {
          inputProps.onBlur(event);
          const sug = suggestions?.[0];
          if (sug) {
            onBlurCallback?.(sug.description);
          }
        };

        return (
          <InputWrapper>
            <Input
              style={{ height: "48px", textOverflow: "ellipsis" }}
              leftIcon={searchIcon}
              {...inputProps}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              onFocus={onFocusCallback}
            />
            {(loading || !!suggestions.length) && (
              <SuggestionWrapper>
                {loading && <SuggestionItem>Loading...</SuggestionItem>}
                {!!suggestions.length && (
                  <>
                    {suggestions.map((suggestion, idx) => (
                      <SuggestionItem
                        {...getSuggestionItemProps(suggestion, {})}
                        key={suggestion.placeId + idx}
                      >
                        {suggestion.description}
                      </SuggestionItem>
                    ))}
                  </>
                )}
              </SuggestionWrapper>
            )}
          </InputWrapper>
        );
      }}
    </GooglePlacesAutocomplete>
  );
};

export default PlacesAutocomplete;
