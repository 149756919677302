// libs
import styled from "styled-components";
// theme
import { colors, typography } from "src/theme";
import { fontFamily, regular, remCalc } from "src/theme/typography";
import { FlexColumn } from "../common";

interface InputType {
  error?: boolean;
}

export const InputWrapper = styled.div<InputType>`
  font-family: ${typography.fontFamily};
  padding: 8px 12px;
  border: ${({ error }) => (error ? `1px solid ${colors.red}` : `1px solid ${colors.greyLight}`)};
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
`;
export const Container = styled(FlexColumn)<InputType>``;

export const Input = styled.input`
  background-color: transparent;
  border-radius: 8px;
  border: none;
  box-sizing: border-box;
  outline: none;
  width: 100%;

  font-family: ${fontFamily};
  font-size: ${remCalc(20)};
  font-weight: ${regular};
  line-height: ${remCalc(28)};
  color: ${colors.greyDark};
`;
